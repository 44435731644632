:root {
  --primary-blue: #34769a;
  --white: #ffffff;
}

/* Newer stuff */
html, body {
  height: 100%;
  margin: 0; 
  font-family: Helvetica, sans-serif;
  color: #455;
  line-height: 1.5rem;
  background-color: var(--primary-blue);
  overflow-x: hidden;
}

.app {
  width: 100%;
  height: 100vh;
}

.logo {
  height: 50px;
  margin: 100px 0; 
}

.entity-logo {
  height: 50px;
  margin-top: 20px; 
  margin-bottom: 50px;
}

.container {
  width: 75%;
  height: 100%;
  margin: 0;
  background-color: #f0f0f0;
  padding: 0 40px;
}

.copyright {
  font-size: small;
}

/* Flexbox properties */
.d-flex {
  display: flex;
  &.column {
    flex-direction: column;
  }
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.head-text {
  text-align: left !important;
  font-size: 20px;
  font-weight: 700;
}

#menu {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#menu a {
  margin-right: 10px
}

.last {
  background: url(https://wayf.incommonfederation.org/DS/images/separator.png) no-repeat scroll left center transparent;
  padding-left: 8px;
  float: left;
  list-style: none;
}

/* Text truncation */
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sa-button, .g-button, .c-button {
  width: 328px; /* Set a fixed width for all 3 buttons */
  box-sizing: border-box;
  font-weight: 500;
  height: 60px;
}

.sa-button, .sa-access-text {
  font-family: 'Arial', sans-serif;
  line-height: 1.4;
}

.sa-button {
  margin: 20px 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  background-color: var(--primary-blue);
  border-radius: 5px;
  padding: 12px;
  cursor: pointer;
  text-decoration: none; /* Remove underline from the link */
}

.sa-button:hover, .sa-button:focus {
  box-shadow: 0 0 0 1px rgba(0, 122, 204, 0.7), 0 0 0 4px rgba(0, 122, 204, 0.7);
  outline: none;
}

.sa-button-logo-wrap {
  text-align: center;
  width: 50px;
  height: 100%;
  border-right: 1px solid var(--white);
  padding: 5px 5px 5px 0;
  display: inline-block;
  vertical-align: top;
}

.sa-button-logo {
  width: 30px;
  display: inline-block;
  vertical-align: middle;
}

.sa-button-text {
  padding-left: 10px;
  text-align: center;
  width: auto;
  color: var(--white);
}

.sa-button-text-primary {
  font-size: 14px;
  font-weight: 700;
}

/* Styling of the "Add or change institution" text under the button */
.sa-access-text {
  cursor: pointer;
  text-align: left;
  width: 100%;
  margin-top: 5px;
  font-size: 13px;
  font-weight: 400;
  line-height: 1.46;
  color: var(--primary-blue);
}

.sa-cta-access, .sa-cta-plus {
  display: inline;
  padding: 0 3px;
}

.sa-cta-plus > img {
  height: 13px;
}
.g-button {
  margin: 20px 0;
  display: flex;
  align-items: center;
  background-color: #4285F4; /* Google Blue */
  color: #fff;
  padding: 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.g-button:hover {
  background-color: #357ABD; /* Darker shade of Google Blue on hover */
}

.g-button svg {
  margin-right: 10px; /* Adjust the space between the logo and text */
}

/* Center text vertically within the button */
.g-button span {
  display: inline-block;
  vertical-align: middle;
}

/* Remove default button outline */
.g-button:focus {
  outline: none;
}

.c-button {
  margin: 20px 0;
  display: flex;
  align-items: center;
  background-color: #ccc; /* Change to your preferred color */
  color: #000; /* Text color */
  padding: 12px 20px; /* Adjust padding as needed */
  border: 2px solid #000;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family: 'Arial', sans-serif;
  font-size: 13px;
  font-weight: 500; /* Adjust font properties as needed */
  height: 60px; /* Set a fixed height for both buttons (adjust as needed) */
  text-align: left; /* Align text to the left */
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis;
}

.c-button-icon {
  width: 45px;
  height: 45px;
  vertical-align: middle;
}

.c-button:hover {
  background-color: #eee; /* Darker shade of your color on hover */
}

.c-button svg {
  width: 40px; /* Adjust the SVG size as needed */
  height: 40px;
  margin-right: 10px; /* Adjust the space between the logo and text */
  display: inline;
}

.c-button span {
  display: inline-block;
  vertical-align: middle;
}


#container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 3px;
}

#containerfaq {
  display: flex;
  flex-direction: column;
  align-items: left;
  border: 1px solid rgba(0,0,0,.125);
  border-radius: 3px;
}

header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0,0,0,.125);
  background-color: rgba(0,0,0,.03);
}

#i2-logo {
  flex: none;
  width: 160px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0;
  padding: .75rem 1.25rem;
}

#main-title {
  text-align: center;
  font-size: 2.0rem;
  margin-bottom: 30px;
  line-height: 2.5rem;
}

#main-titlefaq {
  text-align: left;
  font-size: 2.0rem;
  margin-bottom: 30px;
  line-height: 2.5rem;
}

#login {
  text-align: center;
}

.bottom-0 {
  margin-bottom: 0; 
}

footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

#faq-and-lang {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#lang-selector,
#faq-link {
  cursor: pointer;
}

#faq-link a {
  color: inherit;
  text-decoration: none;
}

#faq-return {
  padding: 1rem 2rem;
  cursor: pointer;
}

#app-version {
  font-size: .7rem;
  color: #999;
}

#app-version-faq {
  padding: 1rem;
  font-size: .7rem;
  color: #999;
}

#lang-selector:hover,
#faq-link:hover {
  text-decoration: underline;
}

#faq {
  text-align: left; 
  margin: 20px;
}

#faq summary {
  font-weight: bold;
}

#faq details {
  margin-bottom: 20px;
}

.faq {
  margin: 20px 0;
  font-family: 'Arial', sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-align: left; 
}

@media screen and (min-width: 901px) {
  header {
    margin-bottom: 50px;
  }
  #body {
    padding-right: 150px;
    padding-left: 150px;
    padding-bottom: 100px;
  }
  h1 {
    margin: 20px;
  }
}

@media screen and (max-width: 900px) {
  #root {
    margin: 10% 0 10% 0;
  }
  header {
    margin-bottom: 40px;
  }
  #body {
    padding-right: 50px;
    padding-left: 50px;
    padding-bottom: 50px;
  }
  h1 {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  #md-content {
    margin-right: 2rem;
    margin-left: 2rem;
  }
  .container {
    width: 100%;
    height: 90%; 
  }
  .logo {
    height: 50px;
    margin: 50px 0 100px 0; 
  }
  #menu {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .last {
    background: none; 
    padding-left: 8px;
    float: left;
    list-style: none;
  }
  .logo {
    height: 35px;
    margin: 50px 0;
  }
}
